.convenience-container {
    padding: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
    border-radius: 24px;
    background-color: #fff;
    box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
  }
  
  .convenience-column {
    flex-basis: calc(100% / 3 - 20px);
    margin: 10px 10px;
    text-align: center;
  }
  
  .convenience-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 0 auto 20px;
    background-color: #f8f8f8;
    
    border-radius: 50%;
    font-size: 24px;
  }
  
  .convenience-icon svg {
    color: blueviolet;
  }
  
  h3 {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }
  
  .convenience-column p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #777;
    display: inline;
  }
  @media screen and (max-width: 720px) {
    
    .convenience-container {
        justify-content: center;
    }
    
    .convenience-column {
        flex-basis: calc(100% / 3 - 20px);
        margin: 10px 10px;
        text-align: center;
        
        min-width: 2in;
        
      }
  }
  