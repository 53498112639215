.Goat{
    margin: 30px 30px;
    width: 300px;
    
}
.Goatimage{
    width: 300px;
    height: 400px;
    display: block;
 
    border-radius: 50px;
   
    margin: auto;
}
