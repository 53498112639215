form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    background-color: #fff;
    border-radius: 13px;
    margin: 40px 40px;
  }
  .Note{
    color: red;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  label {
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
  }
  
  input,
  select,
  textarea {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid #ccc;
    margin-top: 5px;
    display: inline-block;
    border-radius: 12px;
    margin-left: 10px;
    vertical-align: top;
    width: 400px;
  }
  
  input[type="file"] {
    margin-top: 10px;
  }
  
  button[type="submit"] {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #3e8e41;
  }
  
  .whatsapp-button {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .whatsapp-button:hover {
    background-color: darkgreen;
  }
  
  .whatsapp-button:active {
    background-color: lightgreen;
  }
  
  @media screen and (max-width:480px) {
    form {
      max-width: 500px;
      margin: 50px auto;
    }
    
    input,
    select,
    textarea {
    
      max-width: 300px;
       
    }
  }
  