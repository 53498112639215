.landing-page {
    
    margin: 0 auto;
    padding: 2rem;
    background-color: black;
    background: linear-gradient(29deg, rgba(134,230,193,1) 0%, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 31%, rgba(151,255,206,1) 55%, rgba(134,250,195,1) 74%);
    margin-top: 40px;
    box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
}
  .landing-page h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .landing-page p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
  
  .landing-page .ant-form-item {
    margin-bottom: 1.5rem;
  }
  
  .landing-page .ant-form-item-label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    
  }
  
  .landing-page .ant-input {
    font-size: 1.2rem;
  }
  
  .landing-page .ant-btn {
    font-size: 1.2rem;
    padding: 0.8rem 2rem;
    margin-top: 1rem;
    scroll-behavior: smooth;
    
  }
  
  .landing-page .trust-indicator {
    font-size: 1.1rem;
    font-style: italic;
    margin-top: 2rem;
  }
  form{
    border: 0in;
    background: linear-gradient(29deg, rgba(134,230,193,1) 0%, rgba(255,255,255,1) 0%, rgba(134,250,195,1) 17%, rgba(252,252,252,1) 46%, rgba(240,240,240,1) 100%);
  }
  
@media screen and (max-width: 480px) {
    .landing-page .ant-form-item-label {
        text-align: center;
    }
    .landing-page .ant-input {
        width: 270px;
        font-size: 12px;
      }
}