/* Select all paragraphs */
.Testimonials{
    padding: 20px 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 13px;
    margin: 40px 40px;
}
p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    
  }
  
  /* Select all h1 headings */
  h1 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  
  /* Select all links */
  a {
    color: #0066cc;
    text-decoration: none;
  }
  
  /* Select all unordered lists */
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  /* Select all list items */
  li {
    margin-bottom: 10px;
  }
  
  /* Select all images */
  img {
    max-width: 100%;
    height: auto;
  }
  
  /* Add a class to style a specific element */
  .special {
    background-color: #ffc;
    border: 1px solid #ff9;
    padding: 10px;
  }
  