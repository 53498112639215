.FAQ{
  text-align: center;
  margin: 20px;
  
}

.dropdown {
    position: relative;
    display: inline-block;
    
    
  }
  
  .dropdown-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 13px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #f9f9f9;
    padding: 12px;
    border: 1px solid #ddd;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    min-width: 400px;
  }
  
  .question {
    margin-bottom: 16px;
  }
  
  .question h3 {
    margin-top: 0;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    display:flex;
  }
  
  .question p {
    margin-bottom: 0;
    width: 280px;
    color: #4CAF50;
  }
  