.blogsection{
    padding: 20px;
    color: #ffffff;
    margin:40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  
    border-radius: 24px;
    background-color:#fff;
    
    box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
    position: relative;
  }
 img{
  width: 100px;
  display: block;
  height: 150px;
  border-radius: 50px;
 
  margin: auto;
  
 }
.blog {
    margin: 20px;
    padding: 20px;
    margin-left: -10px;
    border-radius: 5px;
    color: rgb(136, 136, 218);
   

    
  }
  
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .p-blog{
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    
    
  }
  @media screen and (max-width:480px){
    h2 {
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: center;
    }
    
    p {
      padding: 10px 10px;
      font-size: 13px;
      line-height: 1.5;
      margin-bottom: 20px;
     
      width: 230px;
    }
    
  }