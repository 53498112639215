/* Default styles for the navbar */
nav {
    background-color: rgb(247, 228, 228);
    color: #1a0202;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    box-shadow: 0 18px 24px rgba(0, 0, 0, 0.2);
    
  }
  
   .Home p{
    color: rgb(163, 22, 4);
    font-size: 32px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bolder;
    
  }
  
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  nav ul li {
    margin-right: 20px;
  }
  
  nav ul li:last-child {
    margin-right: 0;
  }
  
  nav ul li a {
    color: #750a0a;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  nav ul li a:hover {
    color: #f00;
  }
  
  nav p {
    margin: 0;
    
  }
  
  /* Media query to hide the navigation links on small screens */
  @media (max-width: 768px) {
    nav ul {
      display: none;
    }
    nav .Home {
      color: rgb(13, 216, 24);
      font-size: 17px;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bolder;
    }
    nav .time {
      color: rgb(22, 22, 0);
      font-size: 9px;
      font-family:monospace;
      font-weight: bolder;
    }
    /* Add a button to toggle the visibility of the navigation links */
    .menu-btn {
      display: block;
      background-color: #333;
      color: #fff;
      border: none;
      font-size: 16px;
      cursor: pointer;
      padding: 10px;
      margin-right: 20px;
    }
  
    .menu-btn:focus {
      outline: none;
    }
  
    /* Show the navigation links when the menu button is clicked */
    nav ul.show {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background-color: #333;
      z-index: 1;
    }
  
    nav ul.show li {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    nav ul.show li:last-child {
      margin-bottom: 0;
    }
  
    nav ul.show li a {
      color: #fff;
    }
  }
  