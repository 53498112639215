footer {
    background-color: #222;
    color: #fff;
    padding: 2rem;
    text-align: center;
  }
  
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  
  .footer-links div {
    width: 100%;
    max-width: 15rem;
    text-align: left;
  }
  
  @media only screen and (min-width: 480px) {
    .footer-links div {
      width: auto;
      max-width: none;
      text-align: center;
    }
  }
  